import { ref, onMounted } from 'vue'
import {useQuery} from '@/services/utils/query'
import {editVoucherNumber, generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import handlePurchaseAndSales from '@/services/modules/purchase'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import handleReceipt from '@/services/modules/receipt'
const {fetchDueList, fetchInvoiceDueList, addPaymentKeyWithBill, fetchSinglePayment} = handlePurchaseAndSales()

export default function useBillPayData (voucher_query) {
    const {fetchCashAndBank} = handleReceipt()
    const {allParams,getQueryString} = useQuery(20)
    const { fetchContactProfiles} = handleContact()
    const {fetchBusinessList} = handleCBusinesses()
    const accountHeads = ref([])
    const business = ref([])
    const contacts = ref([])
    const prefix = ref('')
    const serial = ref('')
    const dueBills = ref([])
    const balance = ref([])
    const advanceAmount = ref()
    const invoiceDueBills = ref([])
    const params = allParams()

    const getDueList = (contactId) => {
        fetchDueList(`?company_id=${params.companyId}&contact_profile_id=${contactId}`)
        .then(res => {
            if (!res.status) {
                balance.value = []
                advanceAmount.value = ''
                dueBills.value = []
                return
            }
            balance.value = res.data?.current_balance
            advanceAmount.value = balance.value
            dueBills.value = prepareDues(res.data)
        })
    }

    const getInvoiceDueList = (contactId) => {
        fetchInvoiceDueList(`?company_id=${params.companyId}&contact_profile_id=${contactId}`)
        .then(res => {
            if (!res.status) {
                return invoiceDueBills.value = []
            }
            invoiceDueBills.value = prepareDues(res.data)
        })
    }

    const getAccountHead = () => {
        fetchCashAndBank(params.companyId)
        .then(res => {
            if(res.status) {
                accountHeads.value = res.data
            }
        })
    }

    const getContactProfiles = () => {
        fetchContactProfiles(`?company_id=${params.companyId}`)
        .then(res => {
            if(res.status) {
                contacts.value = res.data
            }
        })
    }

    const getBusinessList = () => {
        fetchBusinessList(`?company_id=${params.companyId}`)
        .then(res => {
            if(res.status) {
                business.value = res.data
            }
        })
    }

    function prepareDues(dueData) {
        if(dueData.pay_bill.length) {
            return addPaymentKeyWithBill(dueData.pay_bill)
        }
    }

    return {
        getDueList,
        getInvoiceDueList,
        getAccountHead,
        getContactProfiles,
        getBusinessList,
        voucher_query,
        accountHeads,
        contacts,
        invoiceDueBills,
        business,
        prefix,
        serial,
        dueBills,
        balance,
        advanceAmount,
        params,
    }
}
